import { memo, useState } from "react";
import { Button } from "@bit/healthmug.admin.button";

import { Modal } from "@bit/healthmug.admin.modal";

const ConfirmModal = ({ id, title, message, loader, yes, onHide, children }) => {
	const ID = id ? id : "confirmModal";

	// if (onHide)
	// 	useEffect(() => {
	// 		UIkit.util.on(`#${id}`, "hide", () => onHide());
	// 	}, []);

	return (
		<Modal id={ID} micro>
			<div className="uk-modal-body">
				<h4 className="uk-margin-remove uk-text-dark" style={{ fontSize: "17px" }}>
					{title}
				</h4>
				{message ? <div className="uk-margin-small-top">{message}</div> : null}
				{children ? <div>{children}</div> : null}
				<div className="uk-text-right uk-margin-medium-top">
					<Button className="uk-margin-small-right uk-modal-close" style={{ minWidth: "80px" }}>
						No
					</Button>
					<Button onClick={yes} className="uk-button uk-button-primary" style={{ minWidth: "80px" }} loader={loader} disabled={loader}>
						Yes
					</Button>
				</div>
			</div>
		</Modal>
	);
};

function areEqual(prevProps, nextProps) {
	let equal = true;

	if (prevProps.title != nextProps.title) equal = false;
	if (prevProps.message != nextProps.message) equal = false;
	if (prevProps.loader != nextProps.loader) equal = false;
	if (prevProps.children != nextProps.children) equal = false;

	return equal;
}

export default memo(ConfirmModal, areEqual);
