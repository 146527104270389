// COMMON
export const CHANGE_LOADER = "CHANGE_LOADER";
export const UPDATE_INITIAL_STATE = "UPDATE_INITIAL_STATE";
export const APPEND_INITIAL_STATE = "APPEND_INITIAL_STATE";
export const CHANGE_REFINE_VALUES = "CHANGE_REFINE_VALUES";
export const ADD_CSV_FILE = "ADD_CSV_FILE";

// login action types

export const LOGIN_USER = "LOGIN_USER";
export const ON_CHANGE_USER_LOGIN = "ON_CHANGE_USER_LOGIN";
export const UPDATE_ERROR_STATE = "UPDATE_ERROR_STATE";
export const DELETE_ERROR_ON_FOCUS = "DELETE_ERROR_ON_FOCUS";

// bank-accounts action types

export const ON_CHANGE_ACCOUNT_CONTACTS = "ON_CHANGE_ACCOUNT_CONTACTS";
export const FILL_ACCOUNT_DATA = "FILL_ACCOUNT_DATA";
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA";
export const ADD_REMOVE_CONTACTS = "ADD_REMOVE_CONTACTS";
export const SUBMIT_CONTACT_CHANGES = "SUBMIT_CONTACT_CHANGES";
export const UPDATE_CONTACTS_ERROR_STATE = "UPDATE_CONTACTS_ERROR_STATE";
export const CLEAR_ACCOUNT_STATE = "CLEAR_ACCOUNT_STATE";
export const REMOVE_CONTACTS_ERROR_ON_FOCUS = "REMOVE_CONTACTS_ERROR_ON_FOCUS";
export const UPDATE_CONTACTS_STATE = "UPDATE_CONTACTS_STATE";
export const UPDATE_BANK_DETAILS = "UPDATE_BANK_DETAILS";
export const UPDATE_LOADER_OBJECT = "UPDATE_LOADER_OBJECT";

// cash-flows action types

export const FILL_CASH_FLOW_DATA = "FILL_CASH_FLOW_DATA";
export const ADD_NEW_HEAD = "ADD_NEW_HEAD";
export const ON_HEAD_CHANGE = "ON_HEAD_CHANGE";
export const CLEAR_SINGLE_CASH_FLOW = "CLEAR_SINGLE_CASH_FLOW";
export const CLEAR_RECONCILE_DATA = "CLEAR_SINGLE_CASH_FLOW";
export const UPDATE_HEADS_ERROR_STATE = "UPDATE_HEADS_ERROR_STATE";
export const REMOVE_HEADS_ERROR_ON_FOCUS = "REMOVE_HEADS_ERROR_ON_FOCUS";
export const REMOVE_HEAD = "REMOVE_HEAD";
export const CLEAR_UPLOAD_STATE = "CLEAR_UPLOAD_STATE";
export const CHANGE_FILE_BANK_NAME = "CHANGE_FILE_BANK_NAME";
export const UPDATE_REMAINING_AMOUNT = "UPDATE_REMAINING_AMOUNT";
export const UPDATE_RECONCILE_BANK_LIST = "UPDATE_RECONCILE_BANK_LIST";
export const RESET_RECONCILE_BANK_LIST = "RESET_RECONCILE_BANK_LIST";
export const UPDATE_RECONCILE_CASHFLOWS = "UPDATE_RECONCILE_CASHFLOWS";
export const RESET_RECONCILE_CASHFLOWS = "RESET_RECONCILE_CASHFLOWS";
export const UPDATE_UNRECONCILED_CASHFLOW = "UPDATE_UNRECONCILED_CASHFLOW";
export const UPDATE_CHECKED_CASHFLOW = "UPDATE_CHECKED_CASHFLOW";
export const ON_ACTIVITY_FOUR_SELECT = "ON_ACTIVITY_FOUR_SELECT";
export const ADD_UNRECONCILE_CASHFLOW_ID = "ADD_UNRECONCILE_CASHFLOW_ID";
export const UPDATE_OBJECT_ON_RECONCILE = "UPDATE_OBJECT_ON_RECONCILE";

// cash-flow-heads action types

export const ON_CREATE_HEAD_CHANGE = "ON_CREATE_HEAD_CHANGE";
export const ON_FLOW_DIRECTION_CHANGE = "ON_FLOW_DIRECTION_CHANGE";
export const FILL_HEAD_LIST = "FILL_HEAD_LIST";
export const UPDATE_CREATE_HEAD_ERROR_STATE = "UPDATE_CREATE_HEAD_ERROR_STATE";
export const DELETE_CREATE_ERROR_ON_FOCUS = "DELETE_CREATE_ERROR_ON_FOCUS";
export const RESET_REFINE_VALUES = "RESET_REFINE_VALUES";
export const CLEAR_CREATE_HEAD_MODAL = "CLEAR_CREATE_HEAD_MODAL";

// reports action types

export const ON_STATE_CHANGE = "ON_STATE_CHANGE";

// Account Parties action types

export const ON_CHANGE_ACCOUNT_PARTIES = "ON_CHANGE_ACCOUNT_PARTIES";
export const ON_CHANGE_ACCOUNT_PARTY_CONTACTS = "ON_CHANGE_ACCOUNT_PARTY_CONTACTS";
export const ON_CHANGE_ACCOUNT_PARTY_ADDRESS = "ON_CHANGE_ACCOUNT_PARTY_ADDRESS";
export const RESET_CREATE_ACCOUNT_PARTIES_DATA = "RESET_CREATE_ACCOUNT_PARTIES_DATA";
export const ADD_EDIT_ACCOUNT_PARTY_DATA = "ADD_EDIT_ACCOUNT_PARTY_DATA";
export const UPDATE_ACCOUNT_PARTIES_ERROR_STATE = "UPDATE_ACCOUNT_PARTIES_ERROR_STATE";
export const UPDATE_CHECKED_ACCOUNT_PARTY = "UPDATE_CHECKED_ACCOUNT_PARTY";
export const UPDATE_APPROVED_ACCOUNT_PARTY = "UPDATE_APPROVED_ACCOUNT_PARTY";
export const REMOVE_ACCOUNT_PARTY_ERROR_ON_FOCUS = "REMOVE_ACCOUNT_PARTY_ERROR_ON_FOCUS";
export const ADD_ACCOUNT_PARTY_CONTACT = "ADD_ACCOUNT_PARTY_CONTACT";
export const REMOVE_ACCOUNT_PARTY_CONTACT = "REMOVE_ACCOUNT_PARTY_CONTACT";

// Account Parties Payment Action

export const CHANGE_PAYMENT_DATA_WITH_ID = "CHANGE_PAYMENT_DATA_WITH_ID";
export const DELETE_PAYMENT_DATA_WITH_ID = "DELETE_PAYMENT_DATA_WITH_ID";

// Invoice action type
export const NEW_INVOICE_FETCH = "NEW_INVOICE_FETCH";
export const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
export const EDIT_INVOICE_DATA = "EDIT_INVOICE_DATA";
export const RESET_ADD_INVOICE = "RESET_ADD_INVOICE";
export const INVOICE_FILTERS_CHANGE = "INVOICE_FILTERS_CHANGE";
export const CHANGE_INVOICE_REFINE_VALUE = "CHANGE_INVOICE_REFINE_VALUE";
export const CHANGE_INVOICE_TAXABLE_AMOUNT = "CHANGE_INVOICE_TAXABLE_AMOUNT";
export const CHANGE_INVOICE_HEAD_ID = "CHANGE_INVOICE_HEAD_ID";
export const CHANGE_CREATE_INVOICE_DATA = "CHANGE_CREATE_INVOICE_DATA";
export const ADD_INVOICE_HEAD_ERRORS = "ADD_INVOICE_HEAD_ERRORS";
export const REMOVE_ERROR_ON_FOCUS = "REMOVE_ERROR_ON_FOCUS";
export const REMOVE_INVOICE_TAX_ERROR = "REMOVE_INVOICE_TAX_ERROR";
export const REMOVE_HEAD_ERROR_ON_FOCUS = "REMOVE_HEAD_ERROR_ON_FOCUS";
export const APPEND_INVOICE_INITIAL_STATE = "APPEND_INVOICE_INITIAL_STATE";
export const UPDATE_INVOICE_INITIAL_STATE = "UPDATE_INVOICE_INITIAL_STATE";
export const REMOVE_HEAD_BY_INDEX = "REMOVE_HEAD_BY_INDEX";
export const ADD_NEW_INVOICE_HEAD = "ADD_NEW_INVOICE_HEAD";
export const UPDATE_CHECKED_INVOICE = "UPDATE_CHECKED_INVOICE";
export const DELETE_INVOICE_BY_ID = "DELETE_INVOICE_BY_ID";
